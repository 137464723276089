import BaseModel from './BaseModel';
//import Vue from 'vue';
//import lodash from 'lodash';
import { DataConverter as RateDataConverter } from '@/FirestoreDataConverters/Rate.js';


export default class User extends BaseModel {
    constructor(options) {
        super(options);
    }
    searchLocalById(tourId, rateId) {
        return this.db.collection('tours').doc(tourId).collection('localRates').doc(rateId).withConverter(RateDataConverter);
    }
    searchRegularById(tourId, rateId) {
        return this.db.collection('tours').doc(tourId).collection('regularRates').doc(rateId).withConverter(RateDataConverter);
    }
}